import "./ChangeStationsGroupFuelPrices.css";
import dayjs from "dayjs";
import { Typography } from '@mui/material';
import DatePicker from "react-datepicker";
import Button from "../../../../../generalComponents/buttons/Button";
import ModalComponent from "../../../../../generalComponents/modalComponent/ModalComponent";
import WarningModalBody from "../../../../../generalComponents/modalComponent/warningModalBody/WarningModalBody";
// import SuccessAnimation from "../../../../../generalComponents/successAnimation/SuccessAnimation";
import Loader from "../../../../../generalComponents/loaders/Loader";
import TextField from '@mui/material/TextField';
import { changeData } from "../../../../../api/changeData";
import { colors } from "../../../../../assets/styles/colors";
import { paths } from "../../../../../constants/paths/paths";
import { urls } from "../../../../../constants/urls/urls";
import { editToken } from "../../../../../redux/slices/authSlice";
import { useState } from "react";
// import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChangeStationsGroupFuelPrices = ({
    stationGroupId,
    allFuelTypes,
    setIsStationsGroupFuelPricesChanged,
    onCloseHandler,
}) => {
    const [ fuelTypesIdsPrices, setFuelTypesIdsPrices ] = useState({});
    const [ fuelTypesIdsSchedules, setFuelTypesIdsSchedules ] = useState({});
    const [ isScheduled, setIsScheduled ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ warningModalTheme, setWarningModalTheme ] = useState("");
    const [ isOpenWarningModalBody, setIsOpenWarningModalBody ] = useState(false);
    // const [ showSuccessAnimation, setShowSuccessAnimation ] = useState(false);
    // const [ goToBottom, setGoToBottom ] = useState(false);
    // const bottomOfModalRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // useEffect(() => {
    //     if (goToBottom) {
    //         bottomOfModalRef.current.scrollIntoView();
    //     }
    // }, [goToBottom]);

    const onSaveHandler = async () => {
        const objForCall = {
            stationGroupId,
            fuelTypeIdToPrice: {...fuelTypesIdsPrices},
        }

        if (warningModalTheme === "changeBySchedule") {
            objForCall.fuelTypeIdToSchedule = {...fuelTypesIdsSchedules};
            objForCall.isScheduled = isScheduled;

            for (const key in objForCall.fuelTypeIdToSchedule) {
                objForCall.fuelTypeIdToSchedule[key] = new Date(objForCall.fuelTypeIdToSchedule[key]).toISOString();
            }
        }

        setIsLoading(true);
        const response = await changeData(urls.FUEL_PRICES_URL, objForCall);
        setIsLoading(false);

        if (response.status === 200) {
            setIsStationsGroupFuelPricesChanged(Math.random());
            onCloseHandler();
            // setGoToBottom(true);
            // setShowSuccessAnimation(true);
            // setTimeout(() => {
            //     onCloseHandler();
            // }, 2500);
        } else if (response.status === 401) {
            dispatch(editToken(""));
            localStorage.clear();

            navigate(paths.LOGIN);
        }
    };

    return (
        <div className="change-stations-group-fuel-prices">
            <div
                style={{
                    width: "1050px"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        textAlign: "center",
                        marginBottom: "25px"
                    }}
                >
                    <Typography 
                        variant="h7" 
                        component="h4"
                        style={{
                            color: colors.cancelBgColor
                        }}
                    >
                        {"⚠️ " + t("generalQuestionsTexts.fuelPriceWillBeChangedAfter15Minutes")}
                    </Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        marginBottom: "10px"
                    }}
                >
                    <div
                        style={{
                            width: "265px",
                            backgroundColor: "rgba(0, 0, 0, 0.06)",
                            textAlign: "center",
                            marginRight: "5px"
                        }}
                    >
                        <Typography 
                            variant="h7" 
                            component="h4"
                            className="text-input-separate-label-text"
                        >
                            {t("fuelPrices.fuelType")}
                        </Typography>
                    </div>
                    <div
                        style={{
                            width: "120px",
                            backgroundColor: "rgba(0, 0, 0, 0.06)",
                            textAlign: "center",
                            marginRight: "5px"
                        }}
                    >
                        <Typography 
                            variant="h7" 
                            component="h4"
                            className="text-input-separate-label-text"
                        >
                            {t("fuelPrices.currentPrice")}
                        </Typography>
                    </div>
                    <div
                        style={{
                            width: "260px",
                            backgroundColor: "rgba(0, 0, 0, 0.06)",
                            textAlign: "center",
                            marginRight: "5px"
                        }}
                    >
                        <Typography 
                            variant="h7" 
                            component="h4"
                            className="text-input-separate-label-text"
                        >
                            {t("fuelPrices.lastChangeDate")}
                        </Typography>
                    </div>
                    <div
                        style={{
                            width: "120px",
                            backgroundColor: "rgba(0, 0, 0, 0.06)",
                            textAlign: "center"
                        }}
                    >
                        <Typography 
                            variant="h7" 
                            component="h4"
                            className="text-input-separate-label-text"
                        >
                            {t("fuelPrices.newPrice")}
                        </Typography>
                    </div>
                    <div
                        style={{
                            width: "265px",
                            backgroundColor: "rgba(0, 0, 0, 0.06)",
                            textAlign: "center",
                            marginLeft: "5px"
                        }}
                    >
                        <Typography 
                            variant="h7" 
                            component="h4"
                            className="text-input-separate-label-text"
                        >
                            {t("fuelPrices.newChangeDate")}
                        </Typography>
                    </div>
                </div>
                {
                    allFuelTypes.map((fuelType) => {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "10px"
                                }}
                            >
                                <div
                                    style={{
                                        width: "265px",
                                        display: "flex",
                                        alignItems: "center",
                                        marginRight: "5px"
                                    }}
                                >
                                    <Typography 
                                        variant="h7" 
                                        component="h4"
                                        className="text-input-separate-label-text"
                                    >
                                        {fuelType.name}
                                    </Typography>
                                </div>
                                <div
                                    style={{
                                        width: "120px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginRight: "5px"
                                    }}
                                >
                                    <Typography 
                                        variant="h7" 
                                        component="h4"
                                        className="text-input-separate-label-text"
                                    >
                                        {fuelType.price}
                                    </Typography>
                                </div>
                                <div
                                    style={{
                                        width: "260px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginRight: "5px"
                                    }}
                                >
                                    <Typography 
                                        variant="h7" 
                                        component="h4"
                                        className="text-input-separate-label-text"
                                    >
                                        {dayjs(fuelType.lastUpdateTime).format("DD-MM-YYYY HH:mm:ss")}
                                    </Typography>
                                </div>
                                <div
                                    style={{
                                        width: "120px",
                                    }}
                                >
                                    <TextField
                                        hiddenLabel
                                        id="filled-hidden-label-small"
                                        defaultValue={fuelType.scheduledPrice}
                                        variant="filled"
                                        size={"small"}
                                        onChange={(e) => {
                                            setFuelTypesIdsPrices({
                                                ...fuelTypesIdsPrices,
                                                [fuelType.id]: Number(e.target.value)
                                            })
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        width: "265px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: "5px"
                                    }}
                                >
                                    <DatePicker 
                                        dateFormat="dd-MM-yyyy HH:mm"
                                        timeFormat="HH:mm"
                                        isClearable
                                        showIcon
                                        showTimeSelect
                                        timeIntervals={1}
                                        minDate={Date.now()}
                                        showYearDropdown
                                        selected={
                                            fuelTypesIdsSchedules[fuelType.id] ? fuelTypesIdsSchedules[fuelType.id] : 
                                            fuelType.scheduleTime ? new Date(fuelType.scheduleTime) : null
                                        }
                                        onChange={(date) => {
                                            setFuelTypesIdsSchedules({
                                                ...fuelTypesIdsSchedules,
                                                [fuelType.id]: date
                                            });
                                            setIsScheduled(true);
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="change-stations-group-fuel-prices-buttons">
                <Button label={t("fuelPrices.changeNow")}
                        backgroundColor={colors.successBgColor}
                        hoverColor={colors.successHoverColor}
                        color={colors.successCancelColor}
                        marginRight={"30px"}
                        onClickHandler={() => {
                            setWarningModalTheme("changeNow");
                            setIsOpenWarningModalBody(true);
                        }} />
                <Button label={t("fuelPrices.changeBySchedule")}
                        backgroundColor={colors.successBgColor}
                        hoverColor={colors.successHoverColor}
                        color={colors.successCancelColor}
                        marginRight={"30px"}
                        onClickHandler={() => {
                            setWarningModalTheme("changeBySchedule");
                            setIsOpenWarningModalBody(true);
                        }} />
                <Button label={t("operations.cancel")}
                        backgroundColor={colors.cancelBgColor}
                        hoverColor={colors.cancelHoverColor}
                        color={colors.successCancelColor}
                        onClickHandler={onCloseHandler} />    
            </div>
            {/* <div ref={bottomOfModalRef}></div> */}
            {isOpenWarningModalBody &&
                <ModalComponent onCloseHandler={() => setIsOpenWarningModalBody(false)}
                                isOpen={isOpenWarningModalBody}
                                body={<WarningModalBody warningTitle={t("generalQuestionsTexts.attention")}
                                                        warningText={warningModalTheme === "changeNow" ? 
                                                            t("generalQuestionsTexts.groupFuelTypesPricesWillBeChangedNow") : 
                                                            t("generalQuestionsTexts.groupFuelTypesPricesWillBeChangedBySchedule")
                                                        }
                                                        onSaveHandler={() => {
                                                            setIsOpenWarningModalBody(false);
                                                            onSaveHandler();
                                                        }}
                                                        onCloseHandler={() => setIsOpenWarningModalBody(false)} />}
                                closeImageUrl="../img/x.svg" />
            }
            {/* {showSuccessAnimation &&
                <SuccessAnimation />
            } */}
            {isLoading &&
                <Loader />
            }
        </div>
    );
};

export default ChangeStationsGroupFuelPrices;